import React from "react";

import { Trans } from "next-i18next";

export const ReferralText = ({ title }) => {
  return (
    <p className="request-form__referral">
      <Trans
        i18nKey={"referral_club_text"}
        values={{ title }}
        components={[<span className="request-form__slug" />]}
      />
    </p>
  );
};
