import React from "react";

import { CLUB_BY_SLUG } from "../../../../../graphql/queries";
import { ReferralText } from "./ReferralText";
import { useQuery } from "@apollo/client";

export const ReferralTextContainer = ({ slug }) => {
  const { data: { clubBySlug: { title } = {} } = {} } = useQuery(CLUB_BY_SLUG, {
    variables: { slug },
    skip: !slug,
  });

  return !!title && <ReferralText title={title} />;
};
