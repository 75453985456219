export const ACTIVITY_MORE_BUTTON = [
  {
    slug: "more",
    title: "more_dots",
  },
];

export const COUNTRY_ROUTE = `/country`;
export const COUNTRY = "country";
export const NOT_FOUND_ROUTE = "/404";
export const NOT_FOUND_CLUB_ROUTE = "/not_found_club";
// export const ALPHA2 = process.env.ALPHA2;
