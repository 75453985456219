import React, { useMemo } from "react";

import { useTranslation } from "next-i18next";

import RequestSection from "../RequestSection";
import clsx from "clsx";

const PriceSection = ({ prices }) => {
  const [t] = useTranslation();

  const pricesBlocks = useMemo(() => {
    const maxCountProposal = (arr, name) => {
      if (!arr) return;
      let maxCount = 0;

      for (const i of prices) {
        const property = i[name];
        if (!property) break;
        maxCount = property.length;
      }

      const elLength = arr.length;

      if (maxCount > elLength)
        return [...arr]
          .sort((a, b) => a.order - b.order)
          .concat(
            Array(maxCount - elLength).fill({ title: "\r\n", isEmpty: true })
          );

      return [...arr].sort((a, b) => a.order - b.order);
    };

    const properties = ["prices", "features"];

    return [...prices]
      .sort((a, b) => a.order - b.order)
      .map((i) => {
        return {
          ...i,
          ...properties.reduce(
            (acc, p) => ({ ...acc, ...{ [p]: maxCountProposal(i[p], p) } }),
            {}
          ),
        };
      });
  }, [prices]);

  return (
    <section className="landing-price landing-main-block landing-main">
      <div className="container">
        <h3 className="landing-title" id="_prices">
          {t("price_main_title")}
        </h3>

        <div className="landing-price__inner">
          <div className="landing-price__items-block">
            {pricesBlocks.map(
              ({
                id,
                title,
                header,
                prices,
                features,
                footer,
                default: isBold,
              }) => (
                <div className="landing-price__item" key={id}>
                  {/* <div className="landing-price__item-inner"> */}
                  <div
                    className="landing-price__item-title"
                    style={{
                      color: isBold ? "var(--secondary-color)" : "normal",
                    }}
                  >
                    {title}
                    <span className={"landing-price__item-subtitle"}>
                      {header}
                    </span>
                  </div>
                  {/* <div className="landing-price__item-subtitle">{subTitle}</div> */}
                  <div className="landing-price__item-content">
                    <div className="landing-price__section">
                      {/* <h2 className="landing-price__section-title">
                        {cardFeaturesTitle}
                      </h2> */}
                      {features.map(({ title, isEmpty }, id) => (
                        <div
                          className={clsx("landing-price__section-line", {
                            "landing-price__ability-line": !isEmpty,
                          })}
                          key={id}
                        >
                          {title}
                        </div>
                      ))}
                    </div>
                    <div className="landing-price__section">
                      <p className="landing-price__section-title">
                        {t("price")}
                      </p>
                      {prices.map(
                        ({ title, price, priceFull, currencyTitle }, id) => (
                          <div className="landing-price__section-line" key={id}>
                            {`${title} – `}
                            {priceFull === price ? (
                              <>
                                <span>{price}</span>{" "}
                                <span>{currencyTitle}</span>
                              </>
                            ) : (
                              <>
                                <span>{price}</span> <s>{priceFull}</s>{" "}
                                <span>{currencyTitle}</span>
                              </>
                            )}
                          </div>
                        )
                      )}
                    </div>
                    <p className="landing-price__section">{footer}</p>
                  </div>
                </div>
                // </div>
              )
            )}
          </div>
          <RequestSection />
        </div>
      </div>
      <style jsx global>{`
        @import "./PriceSection.scss?";
      `}</style>
    </section>
  );
};

export default PriceSection;
