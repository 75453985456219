import React from "react";

import Link from "next/link";
import clsx from "clsx";
import Image from "next/image";

function ClubsCards({ clubs, childClass }) {
  return (
    <div className="clubs-cards-block">
      {clubs.map(({ icon, titleShort, slug }, idx) => {
        if (
          !icon
          //  ||
          // (icon.slice(-3).toLowerCase() !== "png" &&
          //   icon.slice(-3).toLowerCase() !== "jpg" &&
          //   icon.slice(-3).toLowerCase() !== "jpeg")
        ) {
          icon = "/default-club-image.png";
        }

        return (
          <div
            className={clsx(childClass ? childClass : "club-card__wrapper")}
            key={idx}
          >
            <Link
              href={`/club/${slug}`}
              passHref
              target={"_blank"}
              className="club-card-inner"
            >
              <>
                <div className="club-card-opacity"></div>
                <div className="club-card-hover-block">
                  <h3 className="club-card-title">{titleShort}</h3>
                </div>

                <Image
                  fill
                  className="club-card-img"
                  src={icon}
                  alt="clubs logo"
                  blurDataURL={icon}
                />
              </>
            </Link>
          </div>
        );
      })}
      <style jsx global>{`
        @import "./ClubsCards.scss?7";
      `}</style>
    </div>
  );
}

export default ClubsCards;
