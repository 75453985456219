import { COUNTRY } from "../../Cities/constants/ownConstants";
import { IS_FULL_CLIENT_SITE } from "../../../../constants/APIKeys";

export const HEADER_NAV_CONTENT = [
  ...(IS_FULL_CLIENT_SITE
    ? [
        {
          buttonTitle: "header_registration_button_title",
          text: "header_registration_title",
          id: 1,
          urlSlug: COUNTRY,
        },
      ]
    : []),
  {
    buttonTitle: "get_consultation",
    text: "connected_clubs",
    id: 2,
    urlSlug: "addclub",
  },
];
