import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import { DROP_SVG } from "../../../constants/svgConstants";

import { useTranslation } from "next-i18next";

import { useLandingSettingsQuery } from "../../../utils/apollo/useLandingSettingsQuery";

const maxDescrLength = 1000;

const SeoTextBlock = ({ isLanding = true, className, isGrey = false }) => {
  const [t] = useTranslation();
  const { data: { social: { seoText = "" } = {} } = {} } =
    useLandingSettingsQuery(isLanding);

  const [showMore, setShowMore] = useState(false);

  const { isFullDescription, shortDescription } = useMemo(() => {
    let descrLength = 0;
    const shortDescription =
      seoText &&
      seoText
        .split(".")
        .filter((sentence) => {
          const lengthSentence = sentence.length;

          if (descrLength + lengthSentence <= maxDescrLength) {
            descrLength += lengthSentence;

            return true;
          }

          if (descrLength < maxDescrLength) descrLength += maxDescrLength;

          return false;
        })
        .join(".");
    const isFullDescription = maxDescrLength >= descrLength;

    return { isFullDescription, shortDescription };
  }, [seoText]);

  return (
    <>
      {seoText && (
        <section className="landing-main-block landing-main">
          <div className={`container ${className ? className : ""}`}>
            <div
              className={clsx({
                [isGrey
                  ? "seo-text-wrapper__hidden--grey"
                  : "seo-text-wrapper__hidden seo-text-wrapper__hidden--full"]:
                  !showMore,
                "seo-text-wrapper__hidden--full": !showMore,
              })}
            >
              {/* seo-text-wrapper */}
              <div
                className="seo-text-block seo-text-block--full description-html"
                dangerouslySetInnerHTML={{
                  __html:
                    !!isFullDescription || !!showMore
                      ? seoText
                      : `${shortDescription}...`,
                }}
              ></div>
            </div>

            {!isFullDescription && (
              <button
                onClick={() => setShowMore(!showMore)}
                className={
                  showMore
                    ? "seo-text-button seo-text-button_less"
                    : "seo-text-button seo-text-button_more"
                }
              >
                {t(showMore ? "hide" : "more")} {DROP_SVG}
              </button>
            )}
          </div>
          <style jsx global>{`
            @import "./SeoTextBlock.scss?";
          `}</style>
        </section>
      )}
    </>
  );
};

SeoTextBlock.propTypes = {
  isFull: PropTypes.bool,
};

SeoTextBlock.defaultProps = {
  isFull: false,
};

export default SeoTextBlock;
