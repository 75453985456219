import React, { useState } from "react";

// import ActiveReview from "../ActiveReview/ActiveReview";
// import AllReviewBlock from "../AllReviewBlock/AllReviewBlock";

import { REVIEWS } from "../../../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";

const ActiveReview = dynamic(() => import("../ActiveReview/ActiveReview"));

const AllReviewBlock = dynamic(() =>
  import("../AllReviewBlock/AllReviewBlock")
);

const ReviewsSection = () => {
  const [t] = useTranslation();

  const [{ reviewsWithVideo, reviewsWithText }, setReviews] = useState({
    reviewsWithVideo: [],
    reviewsWithText: [],
  });

  useQuery(REVIEWS, {
    onCompleted: ({ reviews }) => {
      const reviewsWithVideo = [];
      const reviewsWithText = [];
      [...reviews]
        .sort((a, b) => a.order - b.order)
        .forEach((review) => {
          const { video, text } = review;

          if (video) {
            reviewsWithVideo.push(review);
          } else if (text) {
            reviewsWithText.push(text);
          }
        });
      setReviews({ reviewsWithVideo, reviewsWithText });
    },
  });

  return !!reviewsWithVideo.length || !!reviewsWithText.length ? (
    <section className="landing-reviews landing-main-block landing-main">
      <div className="container">
        <div className="landing-reviews__wrapper" id="_review">
          <h3 className="landing-reviews__main-title landing-title">
            {t("review_main_title")}
          </h3>

          {!!reviewsWithVideo.length && (
            <ActiveReview reviews={reviewsWithVideo} />
          )}
          {!!reviewsWithText.length && (
            <AllReviewBlock reviews={reviewsWithText} />
          )}
        </div>
      </div>
      <style jsx global>{`
        @import "./ReviewsSection.scss";
      `}</style>
    </section>
  ) : null;
};

export default ReviewsSection;
