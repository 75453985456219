import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "next-i18next";
import { useToggle } from "../../../../hooks/useToggle";
import { RequestModal } from "../RequestSection/RequestModal/RequestModal";

import { HASH_PREFIX } from "../../../../hooks/ScrollToAnchor";
import Image from "next/image";
import { CardButton } from "../../../common/CardButton";

const FeaturesSection = ({ features }) => {
  const [t] = useTranslation();

  const [showModal, toggle, off] = useToggle();

  return (
    <section className="landing-features landing-main-block landing-main">
      {showModal && <RequestModal onClose={off} />}
      <div className="container">
        <h3 className="landing-title">{t("additional_possibilities")}</h3>

        <div className="landing-features__inner">
          <div className="landing-features__items-block">
            {[...features]
              .sort((a, b) => a.order - b.order)
              .map(({ id, slug, title, description, image }) => (
                <div
                  id={`${HASH_PREFIX}${slug}`}
                  className="landing-features__item"
                  key={id}
                >
                  <div className="landing-features__item-content">
                    <div className="landing-features__item-text">
                      <p className="landing-features__item-title">{title}</p>
                      <div
                        className="landing-features__item-description"
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      ></div>
                      <CardButton
                        title={t("learn_button_title")}
                        className="landing-features__button landing-main-button"
                        onClick={toggle}
                      />
                    </div>
                    <div className="landing-features__wrapper-image">
                      {image && (
                        <Image
                          src={image}
                          alt={title}
                          className="landing-features__item-image"
                          // layout="responsive"

                          height={600}
                          width={300}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <style jsx global>{`
        @import "./FeaturesSection.scss?7";
      `}</style>
    </section>
  );
};

FeaturesSection.propTypes = {
  partnerSlug: PropTypes.string,
};

FeaturesSection.defaultProps = {
  partnerSlug: "",
};

export default FeaturesSection;
