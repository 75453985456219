import { useQuery } from "@apollo/client";

import { GET_PARTNER_SETTINGS } from "../../graphql/queries";

import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const useLandingSettingsQuery = (isLanding = true) => {
  const {
    query: { partnerSlug: slug },
  } = useRouter();
  const {
    i18n: { language },
  } = useTranslation();

  return useQuery(GET_PARTNER_SETTINGS, {
    variables: { slug: isLanding ? "staff" : slug, language },
  });
};

export { useLandingSettingsQuery };
