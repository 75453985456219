export function Required(value) {
  return value.trim().length > 0 ? "" : "required";
}

export function isNumber(value) {
  return /^\d+$/.test(value.trim()) ? "" : "isNumber";
}

export function isTel(value) {
  const str = value.trim().replace(/[\s\-]/g, "");
  const reg = /^((\+?3)?8)?((0\(\d{2}\)?)|(\(0\d{2}\))|(0\d{2}))\d{7}$/;
  return str.match(reg) !== null ? "" : "isTel";
}

export function isUrl(value) {
  //return /^[^.]+\.[^.]+$/.test(value) ? "" : "isUrl";
  return value.trim().length > 0 ? "" : "isUrl";
}
