import React, { memo } from "react";

import { RequestModal } from "./RequestModal";
import { useTranslation } from "next-i18next";
import { useToggle } from "../../../../hooks/useToggle";
import { CardButton } from "../../../common/CardButton";

const RequestSection = memo(() => {
  const [t] = useTranslation();
  const [showModal, toggle, off] = useToggle();

  return (
    <>
      {showModal && <RequestModal onClose={off} />}

      <div className="landing-request landing-main">
        <div className="container">
          <div className="landing-request__inner">
            <p className="landing-request__description">
              {t("request_description_text")}
            </p>
            <div className="landing-request__spacer" />

            <CardButton
              title={t("send")}
              className="primary-button"
              onClick={toggle}
            />
          </div>
        </div>

        <style jsx global>{`
          @import "./RequestSection.scss?1";
        `}</style>
      </div>
    </>
  );
});

export default RequestSection;
